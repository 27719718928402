import tola from '../assets/tola.png';
import bg from '../assets/bg.jpg';
import profile from '../assets/profile.png';
import circle from '../assets/circle.svg'
import sass from '../assets/sass.png';
import react from '../assets/react.png';
import css from '../assets/css.png';
import js from '../assets/js.png';
import bootstrap from '../assets/bootstrap.png';
import about1 from '../assets/about1.jpg';
import about2 from '../assets/about2.jpg';
import about3 from '../assets/about3.jpg';
import about4 from '../assets/about4.jpg';
import project1 from '../assets/project1.png';
import call from '../assets/call.png';
import mail from '../assets/mail.png';

export default {
    tola,
    bg,
    profile,
    circle,
    sass,
    react,
    css,
    js,
    bootstrap,
    about1,
    about2,
    about3,
    about4,
    project1,
    call,
    mail
   
}